import React from "react";
import Template from '../components/Template/Template';

import img_alior from '../assets/images/banks/b-alior-bank.gif';
import img_bgz from '../assets/images/banks/b-bank-bgz.gif';
import img_bos from '../assets/images/banks/b-bos-bank.gif';
import img_bph from '../assets/images/banks/b-bank-bph.gif';
import img_wbk from '../assets/images/banks/b-bz-wbk.gif';
import img_dnb from '../assets/images/banks/b-dnb-nord.gif';
import img_doutche from '../assets/images/banks/b-deutsche-bank-pbc.gif';
import img_euro from '../assets/images/banks/b-euro-bank.gif';
import img_fm from '../assets/images/banks/b-fm-bank.gif';
import img_getin from '../assets/images/banks/getin-bank-logo.jpg';
import img_idea from '../assets/images/banks/b-idea-bank.gif';
import img_ing from '../assets/images/banks/b-ing-bank-slaski.gif';
import img_intelligo from '../assets/images/banks/b-inteligo.gif';
import img_credi from '../assets/images/banks/b-credit-agricole.gif';
import img_meritum from '../assets/images/banks/b-meritum-bank.gif';
import img_millenium from '../assets/images/banks/b-bank-millennium.gif';
import img_nordea from '../assets/images/banks/b-nordea-bank.gif';
import img_pko from '../assets/images/banks/b-pko-bank-polski.gif';
import img_raif from '../assets/images/banks/b-raiffeisen-bank.gif';
import img_toyota from '../assets/images/banks/b-toyota-bank.gif';
import img_voldz from '../assets/images/banks/b-volkswagen-bank-direct.gif';
import img_city from '../assets/images/banks/b-citi-handlowy.gif';
import img_pariba from '../assets/images/banks/b-bnp-paribas.gif';
import img_mbank from '../assets/images/banks/b-mbank.gif';
import img_poczt from '../assets/images/banks/b-bank-pocztowy.gif';

const Marki = () => {

    return (
        <Template path="/sprawdz_wplate">
           
           <div className="mWidth marki">
                <h1 className="ng2">Sprawdź czy już otrzymałeś wpłatę</h1>

                <div className="bank"><a href="http://www.aliorbank.pl" rel="nofollow"><figure><img src={img_alior} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.bgz.pl" rel="nofollow"><figure><img src={img_bgz} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.bosbank.pl" rel="nofollow"><figure><img src={img_bos} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.bph.pl" rel="nofollow"><figure><img src={img_bph} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.bzwbk.pl" rel="nofollow"><figure><img src={img_wbk} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.dnb.pl" rel="nofollow"><figure><img src={img_dnb} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.deutschebank.pl" rel="nofollow"><figure><img src={img_doutche} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.eurobank.pl" rel="nofollow"><figure><img src={img_euro} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.fmbank.pl" rel="nofollow"><figure><img src={img_fm} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.getinbank.pl" rel="nofollow"><figure><img src={img_getin} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.ideabank.pl" rel="nofollow"><figure><img src={img_idea} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.ingbank.pl" rel="nofollow"><figure><img src={img_ing} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.inteligo.pl" rel="nofollow"><figure><img src={img_intelligo} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.credit-agricole.pl" rel="nofollow"><figure><img src={img_credi} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.meritumbank.pl" rel="nofollow"><figure><img src={img_meritum} alt="" /></figure></a></div>
                <div className="bank"><a href="http://www.millenniumbank.pl" rel="nofollow"><figure><img src={img_millenium} alt="" /></figure></a></div>
                <div className="bank"><a href="http://nordea.pl/" rel="nofollow"><figure><img src={img_nordea} alt="" /></figure></a></div>
                <div className="bank"><a href="https://www.ipko.pl/" rel="nofollow"><figure><img src={img_pko} alt="" /></figure></a></div>
                <div className="bank"><a href="https://www.raiffeisenpolbank.com/" rel="nofollow"><figure><img src={img_raif} alt="" /></figure></a></div>
                <div className="bank"><a href="https://www.toyotabank.pl/" rel="nofollow"><figure><img src={img_toyota} alt="" /></figure></a></div>
                <div className="bank"><a href="https://www.vwbank.pl/" rel="nofollow"><figure><img src={img_voldz} alt="" /></figure></a></div>
                <div className="bank"><a href="https://www.online.citibank.pl/" rel="nofollow"><figure><img src={img_city} alt="" /></figure></a></div>
                <div className="bank"><a href="https://www.bgzbnpparibas.pl/" rel="nofollow"><figure><img src={img_pariba} alt="" /></figure></a></div>
                <div className="bank"><a href="https://www.mbank.com.pl" rel="nofollow"><figure><img src={img_mbank} alt="" /></figure></a></div>
                <div className="bank"><a href="https://www.pocztowy.pl/" rel="nofollow"><figure><img src={img_poczt} alt="" /></figure></a></div>
            </div>
    

        </Template>
    )
}

export default Marki
